<template>
  <div>
    <v-container fluid class="pa-6 ">
      <DatatableV2 :displayTitle="false" :flat="false" :url="apiUrl"/>
    </v-container>
  </div>
</template>

<script>
import DatatableV2 from '@/components/DatatableV2';
import GenericDataService from '@/services/GenericDataService';

export default {
  name: "CampaignInList",
  components: {
    DatatableV2
  },
  data() {
    return {
      apiUrl: "/retroplanning/list?type=CAMP_IN",
    }
  }
  
};
</script>